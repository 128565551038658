import Https from "../../services/api/apiClient";
import * as axios from 'axios';
import apiAddress from '../../services/api/apiAddress';

export const FETCH_BOOKS = 'FETCH_BOOKS'; // frontend customer (no disabled books)
export const FETCH_ALL_BOOKS = 'FETCH_ALL_BOOKS'; // frontend admin (includes disabled books)
export const FETCH_CHAPTER = 'FETCH_CHAPTER';
export const FETCH_BOOK = 'FETCH_BOOK';
export const SEARCH_RESULT = 'SEARCH_RESULT';
export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SEARCH_RESULT_INITIALS = 'SEARCH_RESULT_INITIALS';
export const SEARCH_RESULT_CHAPTERS = 'SEARCH_RESULT_CHAPTERS';
export const BOOKS_POPULAR = 'BOOKS_POPULAR';
export const BOOKS_NEW = 'BOOKS_NEW';

export function search(searchTerm, successCB, errorCB) {
  return function (dispatch, getState) {
    dispatch({
      type: SEARCH_RESULT,
      loadingResult: true,
      searchResult: [],
        searchTerm: searchTerm,
    });
    if(searchTerm.length === 0) {
      dispatch({
        type: SEARCH_RESULT,
        searchResult: [],
        searchTerm: "",
        loadingResult:false,
      })
    } else {
      dispatch({
        type: SEARCH_QUERY,
        searchTerm: searchTerm
      })
      Https.get('/api/v1/content/search/' + searchTerm)
        .then(res => {
          dispatch({
            type: SEARCH_RESULT,
            searchResult: res.data.data.searchResults,
            searchTerm: res.data.data.searchTerm,
            loadingResult:false,
          })
          if (successCB) successCB(res.data.data.searchResults);
        })
        .catch(err => {
          if(errorCB) errorCB(err);
          console.log('Couldnt not fetch chapter ', err)
        })
    }

  };
}

export function searchInBook(bookid, searchTerm, successCB, errorCB) {
    return function (dispatch, getState) {
        if (searchTerm.length === 0) {
            dispatch({
                type: SEARCH_RESULT_CHAPTERS,
                searchResultChapters: [],
            });
        } else {
            Https.get('/api/v1/content/search/' + searchTerm + '/' + bookid)
                .then(res => {
                    dispatch({
                        type: SEARCH_RESULT_CHAPTERS,
                        searchResultChapters: res.data.data,
                    });
                    if (successCB) successCB(res.data.data);
                })
                .catch(err => {
                    if (errorCB) errorCB(err);
                    // console.log('Couldnt not fetch searchInBook ', err)
                });
        }
    }
}

export function searchForPreview(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/content/bookspopular')
            .then(res => {
                dispatch({
                    type: BOOKS_POPULAR,
                    bookspopular: res.data.data.books,
                });
                if (successCB) successCB(res.data.data.books);
            })
            .catch(err => {
                if (errorCB) errorCB(err);
            });
    }
}

export function newBooks(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/content/booksnew')
            .then(res => {
                dispatch({
                    type: BOOKS_NEW,
                    booksnew: res.data.data.books,
                });
                if (successCB) successCB(res.data.data.books);
            })
            .catch(err => {
                if (errorCB) errorCB(err);
            });
    }
}


export function searchInitials(letter,successCB,errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/content/search/initials/' + letter)
        .then(res => {
          dispatch({
            type: SEARCH_RESULT_INITIALS,
            authorResults: res.data.data.authorResults,
            titleResults: res.data.data.titleResults,
            searchTermLetter: res.data.data.searchTerm
          })
          if (successCB) successCB();
        })
        .catch(err => {
          dispatch({
            type: SEARCH_RESULT_INITIALS,
            searchResult: [],
          })
          if (errorCB) errorCB();
          console.log('Couldnt not fetch search/initials ', err)
        })
  }
}

export function syncBooks(successCB, errorCB) {
    return function (dispatch, getState) {
        Https.sync('/api/v1/content/books/sync').then(
            (res) => {
                if (successCB) successCB(res);
            },
        ).catch((reject) => {
            if (errorCB) errorCB();
        });
    }

}

export function syncMeta(successCB,errorCB) {
  // set no timeout
  return function (dispatch, getState) {
    const apiClient = axios.create({
      baseURL: apiAddress,
      timeout: 0,
      headers: { "Content-Type": "application/json" },
    });

    apiClient({
      method: 'get',
      url: '/api/v1/content/meta/sync',
    }).then(
    (res)=> {
      if (successCB) successCB();
    },
    
  ).catch((reject) => {
    if (errorCB) errorCB();
  });
  }
  
}

export function fetchChapterById(chapterId, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/content/books/chapter/' + chapterId)
            .then(res => {
                dispatch({
                    type: FETCH_CHAPTER,
                    currentChapter: res.data.data
                })
                if (successCB) successCB();
            })
            .catch(err => {
                console.log('Couldnt not fetch chapter ', err)
                if (errorCB) errorCB(err);
            })
    }
}

export function fetchBookById(bookId, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/content/books/' + bookId)
      .then(res => {
        dispatch({
          type: FETCH_BOOK,
          currentBook: res.data.data
        })
        if(successCB) successCB();
      })
      .catch(err => {
        const {response: {data}} = err;
        if(errorCB) errorCB(data.error);
        console.log('Couldnt not fetch chapter ', data)
      })
  };
}

export function fetchBooksByOriginalAuthor(author, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get('/api/v1/content/booksbyoriginalauthor/' + author)
            .then(res => {
                const books = res.data.data.books;

                dispatch({
                    type: FETCH_BOOKS,
                    books: books
                });

                if(successCB) successCB(res.data.data.books);
            })
            .catch(err => {
                //const {response: {data}} = err;
                console.log('Couldnt not fetch books ', err)
                if(errorCB) errorCB(err.error);

            })
    };
}

export function fetchBookByAuthorAndTitle(author, title, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/content/books/' + author + "/" + title)
      .then(res => {
        const currentBook = res.data.data;
        dispatch({
          type: FETCH_BOOK,
          currentBook
        });

        if(successCB) successCB(res.data.data);
      })
      .catch(err => {
        //const {response: {data}} = err;
        console.log('Couldnt not fetch chapter ', err)
        if(errorCB) errorCB(err.error);
       
      })
  };
}

/* export function syncBooks(successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/content/books/sync')
      .then(console.log)
      .catch(err => {
        const {response: {data}} = err;
        if(errorCB) errorCB(data.error);
        console.log('Couldnt not sync books ', data)
      })
  };
} */

export function fetchAllBooks(successCB, errorCB) {
    // FETCHALLBOOKS without disabled
    return function (dispatch, getState) {
        try {
            Https.get('/api/v1/content/books')
                .then((res) => {
                    dispatch({
                        type: FETCH_BOOKS,
                        books: res.data.data
                    });
                    if (successCB) successCB(res.data.data);
                })
                .catch((err) => {
                    if (errorCB) errorCB(err);
                });

        } catch (err) {
            console.log('Couldnt fetch fetchAllBooks', err)
            if (errorCB) errorCB(err);
        }
    };
}

export function fetchBooks(successCB, errorCB) {
  // FETCHBOOKS including disabled (4 admin view only '_id isbn disabled author bandNr link title metaAuthor metaDescription metaTitle') see backend
  return function (dispatch, getState) {
    Https.get('/api/v1/content/allbooks')
        .then(res => {
          successCB(res.data.data);
          dispatch({
            type: FETCH_ALL_BOOKS,
            books: res.data.data
          });
        })
        .catch(err => {
          if(errorCB) errorCB(err);
          console.log('Couldnt not fetch fetchBooks - ', err)
        })
  };
}

exports.IPREGISTRY_KEY = "mn4lx47qnxxgvl";
exports.IPREGISTRY_URL = "https://eu.api.ipregistry.co/";
exports.API_GETIP_URL = (() => {
    let dynURL;
    if (typeof document != "undefined") {
        if ((dynURL = document.URL)) {
            if (dynURL.search(/dev\.lektueren-verstehen\.de/) >= 0)
                return "https://dev.lektueren-verstehen.de/infothek/getIP.php";
            if (dynURL.search(/localhost/) >= 0)
                return "http://www.bange-local.de/getIP.php";
        }
    }
    return "https://lektueren-verstehen.de/infothek/getIP.php";
})();

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
//bangeComponents
import Button from "../../../componentsBange/button/Button";
import SearchBarContainer from "../../../container/SearchBarContainer";
import AccountBox from "./MainNavbar/AccountBox";
import LoginBox from "./MainNavbar/LoginBox";
import ViewerModal from "./../../../views/ViewerModal";
//routing
// eslint-disable-next-line
import { HashLink as Link } from 'react-router-hash-link';
import { withRouter} from 'react-router';
import { LAYOUT_TYPES } from "../../../utils/constants";
import { loginUser, logout } from "../../../reducers/authentication/actions";
import { connect } from "react-redux";
// style
import './mainNavbar.css';
import * as ContentActions from "../../../reducers/content/actions";
// assets
import logo from '../../../assets/images/bange_logo.png';
import profileIcon from '../../../assets/images/icon_account.svg';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// util
import {isMobile} from "../../../utils/device";

class MainNavbar extends React.Component {


  constructor(props) {
    super(props);
    this.state = { email: "", password: "", mobileMenuOpen: false,toggleLoginBox: false, loggedIn: false, toggleAccountBox: false, toggleSearchBar: false, loading: false, error: false };
  }

  // functionality

  logout = () => {
    this.props.logoutUser();
    this.setState({ toggleAccountBox: false, loggedIn: false, toggleLoginBox: false });
  }


  clickSearchIcon = () => {
    this.setState({ toggleSearchBar: !this.state.toggleSearchBar });
  };

  clickProfileIcon = () => {
    this.setState({ toggleAccountBox: !this.state.toggleAccountBox });
  };

  clickOutsideAccountBox = () => {
    if (this.state.toggleAccountBox) {
      this.setState({ toggleAccountBox: false });
    }

  }

  clickOutsideLoginBox = (email, password) => {
    // console.log("click outside box", email, password);
    this.setState({ toggleLoginBox: false, email: email, password: password });
  }

  onLogin = (email, password) => {
    this.setState({ loading: true });
    if (email.length > 0 && password.length > 0) {

      this.props.login(email, password, () => {
        this.setState({ loading: false, error: false });
      }, () => {
        this.setState({ loading: false, error: true });
      })
    }
  }

  onMenuItemClicked = (link) => {
    this.enableScroll();
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen })
    if (link === "/infothek"){
      window.location = "/infothek";
      return;
    }
    if (link === "/#overview") {
      this.props.location.hash = "#overview";
      this.props.history.push(link)

      return;
    }
    this.props.history.push(link)
  }

  preventDefault = (e) => {
    e = e || window.event;
    if (e.preventDefault)
      e.preventDefault();
    e.returnValue = false;
    // console.log("preventing default scroll here");
  }

  disableScroll = () => {
    window.addEventListener("touchmove", this.preventDefault, { passive: false });
  }

  enableScroll = () => {
    window.removeEventListener("touchmove", this.preventDefault);
  }

  onBodyClicked = (event) => {
    if (((this.mobileMenu) && this.mobileMenu.contains(event.target)) || (event.target.id && event.target.id === "accountIcon") || this.container.contains(event.target)) {
    } else {
      this.setState({ mobileMenuOpen: false })
    }
  }

  // lifecycle
  componentDidMount() {
    document.body.addEventListener('click', this.onBodyClicked);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.onBodyClicked);
    window.removeEventListener("touchmove", this.preventDefault);
  }

  onSearchSubmit = () => {
    this.props.history.push("/search");
  }



  isAdmin() {
    if (this.props.user) {
      return this.props.user.roles.includes("admin");
    }
    return false;
  }

  render() {
    const { layout, transparent, searchBar, stickyTop } = this.props;
    const isHeaderNav = layout === LAYOUT_TYPES.HEADER_NAVIGATION;
    const isTransparent = transparent;
    const classes = classNames(
      "desktop-navbar",
      isTransparent && "transparentHeader",
      stickyTop && "fixed-top"
    );

    //inputProps
    if (isMobile) {
      return this.renderMobile();
    }

// actions
    const actionsButtonToggleLoginBox = {
      onClick: (e) => {
        this.setState({ toggleLoginBox: !this.state.toggleLoginBox });
      }
    };

    return (
      <div ref={(ref) => { this.container = ref; }} className={classes}>
        <div style={{ paddingLeft: 30 }} fluid={isHeaderNav.toString() || null}>
          <nav className=" align-items-center navbar navbar-light justify-content-end flex-md-nowrap">
            {isHeaderNav && (
              <div className="mr-auto">
                 <Link to={"/"}>
                  <div style={{ lineHeight: "25px", }}>
                    <div className="d-table m-auto">
                      <img
                        id="main-logo"
                        className="d-inline-block align-top mr-1"
                        src={logo}
                        alt="Bange Logo"
                      />
                    </div>
                  </div>
                  </Link>
              </div>
            )}
            {searchBar && <div className="p-md-2 mx-md-3" >
            {(window.location.href.includes("/search")) && <SearchBarContainer height={40} onSubmit={this.onSearchSubmit} resetable={true} styles={{ width: "100%" }} placeHolder="Nathan der Weise" size="dynamic" colorScheme="white" />}
            {(!window.location.href.includes("/search")) && <SearchBarContainer height={40} onSubmit={this.onSearchSubmit} resetable={false} styles={{ width: "100%" }} placeHolder="Nathan der Weise" size="dynamic" colorScheme="white" />}
            </div>}
            { <div className="p-md-2 mx-md-3 d-none d-md-block menuItem">
              <Link smooth className="menuItem" to={"/#overview"}>Übersicht</Link>
            </div> }
            <div className="p-md-2 mx-md-3 d-none d-md-block menuItem">
              <a className="menuItem" href={"/infothek"}>Infothek</a>
            </div>
            {!this.props.authenticated &&  <div className="p-md-2 mx-md-3 d-none d-md-block menuItem">
              <a className="menuItem" href={"/register"}>Registrieren</a>
            </div>}
            {this.props.authenticated && <div style={{ cursor: "pointer" }} className="p-md-2 mx-3" onClick={this.clickProfileIcon}>
              <img alt="accIcon" id="accountIcon" style={{ height: 35 }} src={profileIcon}></img>
            </div>}
            {!this.props.authenticated && <div className="p-md-2 mx-3" >
              <Button colorScheme="loginBtn transparent" size="medium" {...actionsButtonToggleLoginBox}>Anmelden</Button>
            </div>}
            {this.state.toggleAccountBox && <AccountBox admin={this.isAdmin()} onLogout={this.logout} onClickOutside={this.clickOutsideAccountBox} />}
            {!this.props.authenticated && this.state.toggleLoginBox && !isMobile && <LoginBox email={this.state.email} password={this.state.password} loading={this.state.loading} error={this.state.error} onClickOutside={this.clickOutsideLoginBox} onLogin={this.onLogin} />}
            {!this.props.authenticated && this.state.toggleLoginBox && isMobile && <ViewerModal handleClose={() => this.setState({ toggleLoginBox: false })} noSubError={true} authenticated={this.props.authenticated} open={true} login={true} />}
          </nav>

        </div>

      </div>
    );
  }



  renderMobile = () => {
    const { layout, transparentMobile, searchBar, stickyTop } = this.props;
    const isHeaderNav = layout === LAYOUT_TYPES.HEADER_NAVIGATION;
    const isTransparent = transparentMobile;
    const classes = classNames(
      "general-navbar",
      isTransparent && "transparentHeader",
      stickyTop && "fixed-top"
    );
    return (
      <div id="mobileNav" ref={(ref) => { this.container = ref; }} className={classes}>

        <nav className="navbar navbar-light">
          <div className=" align-items-center justify-content-end flex-md-nowrap" style={{ paddingLeft: 30, width: "100%", height: "84px" }} fluid={isHeaderNav.toString() || null} >
            {isHeaderNav && (
              <div className="mr-auto">
                 <Link to={"/"}>
                  <div style={{ lineHeight: "25px", }}>
                    <div className="d-table m-auto">

                      <img
                        id="main-logo"
                        className="d-inline-block align-top mr-1"
                        src={logo}
                        alt="Bange Logo"
                      />
                    </div>
                  </div>
                  </Link>
              </div>
            )}
            <div>
              {!this.props.authenticated && <IconButton id="navButton" style={{ marginRight: "30px" }} onClick={() => { this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen },()=>{if(this.state.mobileMenuOpen)this.disableScroll();else this.enableScroll()}) }}>
                <MenuIcon style={{ color: "white", fontSize: "30px" }}/>
              </IconButton>}
              {this.props.authenticated && <IconButton id="navButton" style={{ marginRight: "30px" }} onClick={() => { this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen },()=>{if(this.state.mobileMenuOpen)this.disableScroll();else this.enableScroll()}) }}>
                <img alt="accIcon" id="accountIcon" style={{ height: 35 }} src={profileIcon}></img>
              </IconButton>}
            </div>
            {this.state.mobileMenuOpen === true && (
              <div ref={(ref) => { this.mobileMenu = ref }} className={"mobileMenuContainer"} style={{ position: "fixed", top: document.getElementById("mobileNav").getBoundingClientRect().height - window.scrollY, width: "100%" }}>
               <div onClick={() => { this.onMenuItemClicked("/#overview") }} className={"mobileMenuItem"}>
                  Übersicht
                </div>
                <div className={"mobileMenuItem"} onClick={() => { this.onMenuItemClicked("/infothek") }}>
                  Infothek
                </div>
                {!this.props.authenticated &&
                   <div className={"mobileMenuItem"} onClick={() => { this.onMenuItemClicked("/register") }}>
                   Registrieren
                 </div>
                }
                {!this.props.authenticated &&
                  <div onClick={()=>{this.setState({ toggleLoginBox: !this.state.toggleLoginBox ,mobileMenuOpen: !this.state.mobileMenuOpen},()=>{this.enableScroll();}); }} className={"mobileMenuItem"}>
                    Login
                </div>
                }
                {this.props.authenticated &&
                  <div>
                    <div className={"mobileMenuItem"} onClick={() => { this.onMenuItemClicked("/my/titles") }}>
                      Meine Titel
                  </div>
                    <div className={"mobileMenuItem"} onClick={() => { this.onMenuItemClicked("/my/account") }}>
                      Mein Account
                    </div>
                    <div className={"mobileMenuItem"} onClick={() => { this.onMenuItemClicked("/my/abos") }}>
                      Mein Abonnement
                    </div>
                    {this.isAdmin() && <div className="mobileMenuItem" onClick={() => { this.onMenuItemClicked("/admin"); this.props.logoutUser() }}>
                      Administration
                    </div>}
                    <div className="mobileMenuItem" onClick={() => { this.onMenuItemClicked("/"); this.props.logoutUser() }}>
                      Abmelden
                    </div>
                  </div>
                }
              </div>
            )
            }
            {this.state.toggleAccountBox && <AccountBox onLogout={this.logout} onClickOutside={this.clickOutsideAccountBox} />}

            {!this.props.authenticated && this.state.toggleLoginBox && <ViewerModal handleClose={() => this.setState({ toggleLoginBox: false })} noSubError={true} authenticated={this.props.authenticated} open={true} modalStateLogin={true} />}
          </div>
          {searchBar && (<div style={{ width: "100%" }} className=" align-items-center justify-content-center flex-md-nowrap" fluid={isHeaderNav.toString() || null}>
            <div className="mb-3 mt-2 px-2" >
            {(window.location.href.includes("/search")) && <SearchBarContainer height={40} onSubmit={this.onSearchSubmit} resetable={true} styles={{ width: "100%" }} placeHolder="Nathan der Weise" size="dynamic" colorScheme="white" />}
            {(!window.location.href.includes("/search")) && <SearchBarContainer height={40} onSubmit={this.onSearchSubmit} resetable={false} styles={{ width: "100%" }} placeHolder="Nathan der Weise" size="dynamic" colorScheme="white" />}
            </div>
          </div>)}
        </nav>
      </div>
    );
  }

};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};
const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
  user: state.authentication.user,
  searchTerm: state.content.searchTerm
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logout()),
  login: (email, password, successCb, errorCb) => dispatch(loginUser(email, password, successCb, errorCb)),
  search: (searchTerm, successCB, errorCB) => dispatch(ContentActions.search(searchTerm, successCB, errorCB)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar));


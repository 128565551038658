import React, {Fragment} from "react";
import {withRouter} from "react-router-dom";
import ModalContentBuyOrSubscribe from "../componentsBange/ModalContentBuyOrSubscribe/ModalContentBuyOrSubscribe";
import ModalContentLogin from "../componentsBange/ModalContentLogin/ModalContentLogin";
import ModalContentRegister from "../componentsBange/ModalContentRegister/ModalContentRegister";
import ModalContentRegisterSuccess from "../componentsBange/ModalContentRegisterSuccess/modalContentRegisterSuccess";
import ModalContentCoupon from "../componentsBange/ModalContentCoupon/ModalContentCoupon";
import {loginUser} from "../reducers/authentication/actions";
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './viewer.css';
import {fetchBookByAuthorAndTitle, fetchChapterById} from '../reducers/content/actions';
import {orderBook} from '../reducers/order/actions';
import {connect} from 'react-redux';
import ModalContentBuyCheckout from "../componentsBange/ModalContentBuyCheckout/ModalContentBuyCheckout";
import ModalContentAboCheckout from "../componentsBange/ModalContentAboCheckout/ModalContentAboCheckout";
import CheckoutPurchase from "./CheckoutPurchase";
import CheckoutSubscription from "./CheckoutSubscription";
import {getUserActiveSubscription} from "../reducers/user/actions";
import {isMobile} from "../utils/device";
import {getProduct} from "../reducers/products/actions";
import {empty} from "../modules/Functions";

class ViewerModal extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            buyAccess: true,
            openState: null,
            modalState: "",
            modalAction: null,
            email: "",
            password: "",
            loginError: false,
            loginLoading: false
        }
    }

    componentDidMount() {

        setTimeout(() => {
            // check geoIP
            let isEU = window.localStorage.getItem("usr_is_eu"), access = true;
            if (empty(isEU) || isEU === "FALSE") access = false;

            this.setState({buyAccess: access})
        }, 1000);

        // get products
        this.props.getProduct("SINGLE_BOOK");
        this.props.getProduct("FLATRATE");

        if (this.props.checkoutPurchase === true) {
            this.setState({modalState: "checkoutPurchase"});
            return;
        }
        if (this.props.checkoutSubscribe === true) {
            this.setState({modalState: "checkoutSubscribe"});
            return;
        }
        if (this.props.modalStateLogin === true) {
            this.setState({modalState: "login"});
            return
        }
        this.setState({modalState: "preBuyOrSub"});

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.checkoutPurchase !== this.props.checkoutPurchase) {
            if (this.props.checkoutPurchase) this.setState({modalState: "checkoutPurchase"});
        }
        if (prevProps.checkoutSubscribe !== this.props.checkoutSubscribe) {
            if (this.props.checkoutSubscribe) this.setState({modalState: "checkoutSubscribe"});
        }

        if (prevProps.modalStateLogin !== this.props.modalStateLogin) {
            if (this.props.modalStateLogin) this.setState({modalState: "login"});
        }
    }

    onClickKaufen = () => {
        if (this.props.authenticated) {
            // user already logged in , skip to complete purchase state, set pruchase state
            this.setState({modalState: "completeBuy", modalAction: "buy"});
        } else {
            // set modal to login state
            this.setState({modalState: "login", modalAction: "buy"});
        }
    }

    onClickAbo = () => {
        if (this.props.authenticated) {
            // user already logged in , skip to complete purchase state, set pruchase state
            this.setState({modalState: "completeAbo", modalAction: "abo"});
        } else {
            // set modal to login state
            this.setState({modalState: "login", modalAction: "abo"});
        }
    }

    onClickCoupon = () => {
        if (this.props.authenticated) {
            // user already logged in , skip to complete purchase state, set pruchase state
            this.setState({modalState: "completeCoupon", modalAction: "coupon"});
        } else {
            // set modal to login state
            this.setState({modalState: "login", modalAction: "coupon"});
        }
    }

    onLogin = (email, password) => {
        this.setState({loginLoading: true});
        if (email.length > 0 && password.length > 0) {
            this.props.login(email, password, () => {
                // success
                if (this.state.modalAction === "buy") {
                    this.setState({modalState: "completeBuy", loginError: false, loginLoading: false});
                } else if (this.state.modalAction === "coupon") {
                    this.setState({modalState: "completeCoupon", loginError: false, loginLoading: false});
                } else this.setState({modalState: "completeAbo", loginError: false, loginLoading: false});

            }, () => {
                // fail to login
                this.setState({modalState: "login", loginError: true, loginLoading: false});
                console.log(this.state.loginError, "loginerror");
            })
        }
        this.setState({loginLoading: false});
    };

    onContinue = () => {
        if (this.state.modalAction === "buy") {
            this.setState({modalState: "completeBuy", loginError: false, loginLoading: false});
        } else if (this.state.modalAction === "coupon") {
            this.setState({modalState: "completeCoupon", loginError: false, loginLoading: false});
        } else this.setState({modalState: "completeAbo", loginError: false, loginLoading: false});
    }

    renderMobile() {
        return (<Fragment>

            {this.props.open && this.props.noSubError && <div style={{
                position: "fixed",
                top: 0,
                height: "100%",
                width: "100%",
                zIndex: 1035,
                background: "white",
                boxShadow: "0 0 3px 3px rgba(0, 0,0,.1)",
                margin: "auto",
                overflow: "scroll"
            }}>
                <div className="py-3" style={{position: "relative", width: "100%", height: "100%"}}>
                    <IconButton style={{position: "absolute", top: 8, right: 2}} className="modal-close-button"
                                onClick={() => {
                                    this.props.handleClose();
                                    this.setState({modalState: "preBuyOrSub"})
                                }}>
                        <Close style={{color: "#005CA8", width: 35, height: 35}}/>
                    </IconButton>
                    {this.state.modalState === "login" && !this.props.authenticated &&
                        <ModalContentLogin loading={this.state.loginLoading} error={this.state.loginError}
                                           onLogin={this.onLogin}
                                           onClickRegister={() => this.setState({modalState: "register"})}/>}
                    {this.state.modalState === "register" && !this.props.authenticated &&
                        <ModalContentRegister onRegisterSuccess={() => {
                            this.setState({modalState: "registerSuccess"})
                        }}/>}
                    {this.state.modalState === "registerSuccess" && this.props.authenticated &&
                        <ModalContentRegisterSuccess action={this.state.modalAction}
                                                     onContinue={this.onContinue.bind(this)}/>}

                    {(this.state.buyAccess) ? (<Fragment>
                        {this.state.modalState === "completeBuy" && this.props.authenticated &&
                            <ModalContentBuyCheckout taxPercentage={this.props.singleBook.taxPercentage}
                                                     price={(this.props.singleBook ? this.props.singleBook.price : "noprice")}
                                                     book={{
                                                         mediaTypes: this.props.currentBook.mediaTypes,
                                                         isbn: this.props.currentBook.isbn,
                                                         title: this.props.currentBook.title,
                                                         author: this.props.currentBook.originalAuthor,
                                                         id: this.props.currentBook._id,
                                                         description: this.props.currentBook.uTitle
                                                     }}
                                                     onBuy={() => {

                                                     }}
                                                     onBack={() => this.setState({modalState: "preBuyOrSub"})}
                            />}
                        {this.state.modalState === "completeAbo" && this.props.authenticated && <ModalContentAboCheckout
                            onBack={() => this.setState({modalState: "preBuyOrSub"})}
                        />}
                        {this.state.modalState === "completeCoupon" && this.props.authenticated &&
                            <ModalContentCoupon isbn={this.props.currentBook.isbn}
                                                onBack={() => this.setState({modalState: "preBuyOrSub"})}/>}
                        {this.state.modalState === "checkoutPurchase" && <CheckoutPurchase onError={this.props.onError}
                                                                                           onClose={() => {
                                                                                               this.props.handleClose();
                                                                                               this.setState({modalState: "preBuyOrSub"})
                                                                                           }}
                                                                                           paymentId={this.props.paymentId}/>}
                        {this.state.modalState === "checkoutSubscribe" &&
                            <CheckoutSubscription onError={this.props.onError}
                                                  subId={this.props.subId}/>}
                        {this.state.modalState === "preBuyOrSub" && <ModalContentBuyOrSubscribe
                            singleBookPrice={(this.props.singleBook ? this.props.singleBook.price : "noprice")}
                            flatratePrice={(this.props.flatrate ? this.props.flatrate.price : "noprice")}
                            taxPercentageSingle={this.props.singleBook ? this.props.singleBook.taxPercentage : "notax"}
                            taxPercentageFlatrate={this.props.flatrate ? this.props.flatrate.taxPercentage : "notax"}
                            book={{
                                mediaTypes: this.props.currentBook.mediaTypes,
                                isbn: this.props.currentBook.isbn,
                                title: this.props.currentBook.title,
                                author: this.props.currentBook.originalAuthor,
                                description: this.props.currentBook.uTitle
                            }}
                            onClickKaufen={this.onClickKaufen}
                            onClickAbo={this.onClickAbo}
                            onClickCoupon={this.onClickCoupon}/>}
                    </Fragment>) : (<Fragment>
                        <div className="row mt-5">
                            <div className="col-12 col-sm-12">
                                <p className="pt-0 pl-5 pr-5 pb-1 m-0" style={styles.p}>
                                    Herzlich Willkommen auf dem Lektürehilfen-Portal von Königs
                                    Erläuterungen!<br/>
                                    Im Moment können leider nur Kunden aus EU-Mitgliedstaaten einen
                                    Einzelkauf tätigen oder ein Abonnement abschließen.<br/>
                                    Die Schnellübersichten der einzelnen Titel und die Infothek stehen
                                    dennoch wie gewohnt zur Verfügung.<br/>
                                    Viel Spaß beim Stöbern!<br/><br/>
                                    Die Königs-Erläuterungen-Redaktion
                                </p>
                            </div>
                        </div>
                    </Fragment>)}
                </div>
            </div>}
        </Fragment>)
    }

    render() {
        if (isMobile) {
            return this.renderMobile();
        }
        return (<Fragment>
            {this.props.open && this.props.noSubError && <div id="blurbackground" style={{
                position: "absolute",
                overflow: "hidden",
                height: this.props.readerHeight != null ? this.props.readerHeight : "100%",
                width: this.props.readerWidth != null ? this.props.readerWidth : "100%",
                background: "#00000080"
            }}></div>}
            {this.props.open && this.props.noSubError && <div id="hiddenmodalbg" className="px-1" style={{
                position: "absolute",
                background: "transparent",
                width: this.props.readerWidth != null ? this.props.readerWidth : "100%",
                height: this.props.readerHeight != null ? this.props.readerHeight : "100%",
                overflow: "auto"
            }}>
                <div aos-init="true" aos-animate="true" data-aos="fade-up" data-aos-duration={700} style={{
                    background: "white",
                    boxShadow: "0 0 3px 3px rgba(0, 0,0,.1)",
                    borderRadius: 20,
                    margin: "auto",
                    marginTop: 20,
                    marginBottom: 20,
                    maxWidth: "700px",
                    minWidth: "400px"
                }}>
                    <div className="py-3"
                         style={{position: "relative", width: "100%", height: "100%", zIndex: 1000}}>
                        <IconButton style={{position: "absolute", top: 10, right: 10}}
                                    className="modal-close-button"
                                    onClick={() => {
                                        this.props.handleClose();
                                        this.setState({modalState: "preBuyOrSub"})
                                    }}>
                            <Close style={{color: "#005CA8", width: 28, height: 28}}/>
                        </IconButton>
                        {this.state.modalState === "login" && !this.props.authenticated &&
                            <ModalContentLogin loading={this.state.loginLoading}
                                               error={this.state.loginError}
                                               onLogin={this.onLogin}
                                               onClickRegister={() => this.setState({modalState: "register"})}
                            />}
                        {this.state.modalState === "register" && !this.props.authenticated &&
                            <ModalContentRegister onRegisterSuccess={() => {
                                this.setState({modalState: "registerSuccess"})
                            }}
                            />}
                        {this.state.modalState === "registerSuccess" && this.props.authenticated &&
                            <ModalContentRegisterSuccess onContinue={this.onContinue.bind(this)}
                            />}
                        {this.state.buyAccess ? (<Fragment>
                            {this.state.modalState === "completeBuy" && this.props.authenticated &&
                                <ModalContentBuyCheckout
                                    taxPercentage={this.props.singleBook.taxPercentage}
                                    price={(this.props.singleBook ? this.props.singleBook.price : "noprice")}
                                    book={{
                                        mediaTypes: this.props.currentBook.mediaTypes,
                                        isbn: this.props.currentBook.isbn,
                                        title: this.props.currentBook.title,
                                        author: this.props.currentBook.originalAuthor,
                                        id: this.props.currentBook._id,
                                        description: this.props.currentBook.uTitle
                                    }}
                                    onBuy={() => {
                                    }}
                                    onBack={() => this.setState({modalState: "preBuyOrSub"})}
                                />}
                            {this.state.modalState === "completeAbo" && this.props.authenticated &&
                                <ModalContentAboCheckout
                                    onBack={() => this.setState({modalState: "preBuyOrSub"})}
                                />}
                            {this.state.modalState === "completeCoupon" && this.props.authenticated &&
                                <ModalContentCoupon isbn={this.props.currentBook.isbn}
                                                    onBack={() => this.setState({modalState: "preBuyOrSub"})}
                                />}
                            {this.state.modalState === "checkoutPurchase" && this.props.authenticated &&
                                <CheckoutPurchase onError={this.props.onError}
                                                  price={(this.props.singleBook ? this.props.singleBook.price : "noprice")}
                                                  onClose={() => {
                                                      this.setState({modalState: "preBuyOrSub"});
                                                      this.props.handleClose();
                                                  }}
                                                  paymentId={this.props.paymentId}
                                />}
                            {this.state.modalState === "checkoutSubscribe" && this.props.authenticated &&
                                <CheckoutSubscription onError={this.props.onError}
                                                      subId={this.props.subId}
                                                      onClose={() => {
                                                          this.setState({modalState: "preBuyOrSub"});
                                                          this.props.handleClose();
                                                      }}
                                />}
                            {this.state.modalState === "preBuyOrSub" && <ModalContentBuyOrSubscribe
                                singleBookPrice={(this.props.singleBook ? this.props.singleBook.price : "noprice")}
                                taxPercentageSingle={this.props.singleBook ? this.props.singleBook.taxPercentage : "notax"}
                                taxPercentageFlatrate={this.props.flatrate ? this.props.flatrate.taxPercentage : "notax"}
                                flatratePrice={(this.props.flatrate ? this.props.flatrate.price : "noprice")}
                                book={{
                                    mediaTypes: this.props.currentBook.mediaTypes,
                                    isbn: this.props.currentBook.isbn,
                                    title: this.props.currentBook.title,
                                    author: this.props.currentBook.originalAuthor,
                                    description: this.props.currentBook.uTitle
                                }}
                                onClickKaufen={this.onClickKaufen}
                                onClickAbo={this.onClickAbo}
                                onClickCoupon={this.onClickCoupon}
                            />}
                        </Fragment>) : (<Fragment>
                            <div className="row mt-5">
                                <div className="col-12 col-sm-12">
                                    <p className="pt-0 pl-5 pr-5 pb-1 m-0" style={styles.p}>
                                        Herzlich Willkommen auf dem Lektürehilfen-Portal von Königs
                                        Erläuterungen!<br/>
                                        Im Moment können leider nur Kunden aus EU-Mitgliedstaaten einen
                                        Einzelkauf tätigen oder ein Abonnement abschließen.<br/>
                                        Die Schnellübersichten der einzelnen Titel und die Infothek
                                        stehen dennoch wie gewohnt zur Verfügung.<br/>
                                        Viel Spaß beim Stöbern!<br/><br/>
                                        Die Königs-Erläuterungen-Redaktion
                                    </p>
                                </div>
                            </div>
                        </Fragment>)}
                    </div>
                </div>
            </div>}
        </Fragment>)
    }
}

ViewerModal.propTypes = {};

ViewerModal.defaultProps = {};

const mapStateToProps = state => ({
    currentBook: state.content.currentBook,
    authenticated: state.authentication.authenticated,
    flatrate: state.products.flatrate,
    singleBook: state.products.singleBook
});

const mapDispatchToProps = dispatch => ({
    fetchBookByAuthorAndTitle: (author, title, successCb, errorCb) => dispatch(fetchBookByAuthorAndTitle(author, title, successCb, errorCb)),
    fetchChapterById: (chapterId, successCb, errorCb) => dispatch(fetchChapterById(chapterId, successCb, errorCb)),
    orderBook: (bookid, successCb, errorCb) => dispatch(orderBook(bookid, successCb, errorCb)),
    login: (email, password, successCb, errorCb) => dispatch(loginUser(email, password, successCb, errorCb)),
    getUserActiveSubscription: (searchterm, successCb, errorCb) => dispatch(getUserActiveSubscription(searchterm, successCb, errorCb)),
    getProduct: (code, successCb, errorCb) => dispatch(getProduct(code, successCb, errorCb))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewerModal));


const styles = {
    p: {
        fontSize: "14px", lineHeight: "20px", fontWeight: "bold"
    },
};
  
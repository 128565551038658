import React, {Component} from "react";

import Modal from 'react-bootstrap/Modal';

import SearchBarContainer from '../../container/SearchBarContainer';
import MainNavbar from '../../components/layout/MainNavbar/MainNavbar';
import ModalContentSubscribe from "../ModalContentBuyOrSubscribe/ModalContentSubscribe";
import ModalContentLogin from "../ModalContentLogin/ModalContentLogin";
import ModalContentRegister from "../ModalContentRegister/ModalContentRegister";
import ModalContentRegisterSuccess from "../ModalContentRegisterSuccess/modalContentRegisterSuccess";
import {loginUser} from "../../reducers/authentication/actions";
import ModalContentAboCheckout from "../ModalContentAboCheckout/ModalAboCheckout";
import CheckoutPurchase from "../../views/CheckoutPurchase";
import CheckoutSubscription from "../../views/CheckoutSubscription";

// assets
import dudeImg from '../../assets/images/BA_Lessing_Schrift.png';
import keLogo from '../../assets/images/KE_logo.jpg'
// style
import './teaser.css';
import {LAYOUT_TYPES} from "../../utils/constants";
import {fetchBookByAuthorAndTitle, fetchChapterById} from "../../reducers/content/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as ContentService from "../../reducers/content/actions";
import {isMobile} from "../../utils/device";
import {empty} from "../../modules/Functions";

import {
    orderBook
} from '../../reducers/order/actions';

import {getUserActiveSubscription} from "../../reducers/user/actions";
import {getProduct} from "../../reducers/products/actions";
import {Fragment} from "react";
import ModalContentCoupon from "../ModalContentCoupon/ModalContentCoupon";

class Teaser extends Component {

    constructor(props) {
        super(props);


        this.state = {
            buyAccess: true,
            openModal: false,
            openState: null,
            modalState: "",
            modalAction: null,
            email: "",
            password: "",
            loginError: false,
            loginLoading: false,
            activeSubscription: false
        };

        this.handleClose = this.handleClose.bind(this);
    }

    onSearchSubmit = () => {
        this.props.history.push("search")
    }

    componentDidMount() {

        setTimeout(() => {
            // check geoIP
            let isEU = window.localStorage.getItem("usr_is_eu"), access = true;
            if (empty(isEU) || isEU === "FALSE") access = false;
            this.setState({buyAccess: access})
        }, 1000);

        /**
         * <Teaser> only included in Default.js DefaultLayout
         * set props for  <CheckoutSubscription... in Teaser.js (onError)
         * checkoutSubscribe and openModal here depending on window.location.search (subId)
         */
        let checkoutSubscribe = false;
        let openModal = false;
        if (this.props.subId) {
            checkoutSubscribe = true;
            openModal = true;
        }

        // console.log(this.props);

        // get products
        this.props.getProduct("SINGLE_BOOK");
        this.props.getProduct("FLATRATE");
        if (this.props.checkoutPurchase === true) {
            this.setState({modalState: "checkoutPurchase"});
            return;
        }
        if (this.props.checkoutSubscribe === true) {
            this.setState({modalState: "checkoutSubscribe"});
            return;
        }
        if (checkoutSubscribe) {
            this.setState({modalState: "checkoutSubscribe"});
            if (this.props.openModal === true || openModal) {
                this.setState({openModal: true});
                return
            }
            return;
        }
        if (this.props.modalStateLogin === true) {
            this.setState({modalState: "login"});
            return
        }

        this.setState({modalState: "preBuyOrSub"});

        this.props.getUserActiveSubscription('', (as) => {
            this.setState({activeSubscription: true})
        }, (as) => {
            this.setState({activeSubscription: false})
        });
    }

    onLogin = (email, password) => {
        this.setState({loginLoading: true});
        if (email.length > 0 && password.length > 0) {
            this.props.login(email, password, () => {
                // success
                if (this.state.modalAction === 'abo') {
                    this.setState({modalState: "completeAbo", loginError: false, loginLoading: false});
                }
                if (this.state.modalAction === 'coupon') {
                    this.setState({modalState: "completeCoupon", loginError: false, loginLoading: false});
                }
                this.props.getUserActiveSubscription('', (as) => {
                    this.setState({activeSubscription: true})
                }, (as) => {
                    this.setState({activeSubscription: false})
                });
            }, () => {
                // fail to login
                this.setState({modalState: "login", loginError: true, loginLoading: false});
            })
        }
        this.setState({loginLoading: false});
    };

    onContinue = () => {
        if (this.state.modalAction === 'coupon') {
            this.setState({modalState: "completeCoupon", loginError: false, loginLoading: false});
        } else this.setState({modalState: "completeAbo", loginError: false, loginLoading: false});
    }


    renderSearchResults() {
        const {searchResult} = this.props;
        var searchResults = []
        var searchResultsInTitle = [];
        if (searchResult.length > 0) {
            // use results that contain searchTerm in title first
            for (var k = 0; k < searchResult.length; k++) {
                var re = new RegExp(this.props.searchTerm, "gi");
                if (re.test(searchResult[k].title) && searchResultsInTitle.length <= 2) {
                    searchResultsInTitle.push(<SearchItem key={k} item={searchResult[k]}
                                                          className={"searchResultAutoCompleteItem"}>
                    </SearchItem>)
                }
            }
            // console.log("searchresults title ",searchResultsInTitle);
            // if 2 or more results in title , render them
            if (searchResultsInTitle.length > 2) {
                return searchResultsInTitle;
            } else {
                let countResultsToGet = 3 - searchResultsInTitle.length;
                for (var i = 0; i < searchResult.length && i <= countResultsToGet; i++) {
                    searchResults.push(<SearchItem key={i} item={searchResult[i]}
                                                   className={"searchResultAutoCompleteItem"}>
                    </SearchItem>)
                }
                return searchResults
            }

        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.searchResult !== prevProps.searchResult) {
            // console.log("maybe new render here", this.props.searchResult);
            this.render();
        }
        if (prevProps.checkoutPurchase !== this.props.checkoutPurchase) {
            if (this.props.checkoutPurchase) this.setState({modalState: "checkoutPurchase"});
        }
        if (prevProps.checkoutSubscribe !== this.props.checkoutSubscribe) {
            if (this.props.checkoutSubscribe) this.setState({modalState: "checkoutSubscribe"});
        }

        if (prevProps.modalStateLogin !== this.props.modalStateLogin) {
            if (this.props.modalStateLogin) this.setState({modalState: "login"});
        }
    }

    onClickAbo = () => {
        if (this.props.authenticated) {
            // user already logged in , skip to complete purchase state, set pruchase state
            this.setState({modalState: "completeAbo", modalAction: "abo"});
        } else {
            // set modal to login state
            this.setState({modalState: "login", modalAction: "abo"});
        }
    }

    handleClose() {
        this.setState({openModal: false});
        if (this.state.modalState === "checkoutSubscribe") {
            this.props.getUserActiveSubscription('', (as) => {
                this.setState({activeSubscription: true})
            }, (as) => {
                this.setState({activeSubscription: false})
            });
        }
    }

    renderModal() {
        return (<Modal show={this.state.openModal} size="lg">
            <Modal.Header closeButton={false}>
                <Modal.Title id="contained-modal-title-vcenter" style={{width: "100%", textAlign: "left"}}>
                    <button
                        className="MuiButtonBase-root MuiIconButton-root modal-close-button modal-close-right"
                        type="button"
                        style={{}}
                        onClick={this.handleClose}
                    >
                            <span className="MuiIconButton-label">
                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                                 role="presentation"
                                 style={{color: 'rgb(0, 92, 168)', width: 28, height: 28}}>
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            </svg>
                            </span>
                        <span className="MuiTouchRipple-root">
                            </span>
                    </button>
                </Modal.Title>

            </Modal.Header>
            <Modal.Body style={{padding: "2rem"}}>
                {(this.state.buyAccess) ? (<Fragment>
                    {this.state.modalState === "login" && !this.props.authenticated &&
                        <ModalContentLogin loading={this.state.loginLoading} error={this.state.loginError}
                                           onLogin={this.onLogin}
                                           onClickRegister={() => this.setState({modalState: "register"})}/>}
                    {this.state.modalState === "register" && !this.props.authenticated &&
                        <ModalContentRegister onRegisterSuccess={() => {
                            this.setState({modalState: "registerSuccess"})
                        }}/>}
                    {this.state.modalState === "registerSuccess" && this.props.authenticated &&
                        <ModalContentRegisterSuccess onContinue={this.onContinue.bind(this)}/>}
                    {this.state.modalState === "completeAbo" && this.props.authenticated &&
                        <ModalContentAboCheckout
                            onBack={() => this.setState({modalState: "preBuyOrSub"})}/>}
                    {this.state.modalState === "checkoutPurchase" && this.props.authenticated &&
                        <CheckoutPurchase onError={this.props.onError}
                                          price={(this.props.singleBook ? this.props.singleBook.price : "noprice")}
                                          onClose={() => {
                                              this.setState({modalState: "preBuyOrSub"});
                                              this.props.handleClose();
                                          }} paymentId={this.props.paymentId}/>}
                    {this.state.modalState === "checkoutSubscribe" && this.props.authenticated &&
                        <CheckoutSubscription onError={this.props.onError} subId={this.props.subId}
                                              onClose={() => {
                                                  this.setState({modalState: "preBuyOrSub"});
                                                  this.props.handleClose();
                                              }}/>}
                    {this.state.modalState === "preBuyOrSub" && <ModalContentSubscribe taxPercentageFlatrate={0}
                                                                                       flatratePrice={this.props.flatrate ? this.props.flatrate.price : 'noprice'}
                                                                                       onClickAbo={this.onClickAbo}/>}
                    {this.state.modalState === "completeCoupon" && this.props.authenticated &&
                        <ModalContentCoupon isbn={''} showCheckboxes={true}/>}
                </Fragment>) : (<Fragment>
                    <div className="row">
                        <div className="col-12 col-sm-12">
                            Herzlich Willkommen auf dem Lektürehilfen-Portal von Königs Erläuterungen!<br/>
                            Im Moment können leider nur Kunden aus EU-Mitgliedstaaten einen Einzelkauf
                            tätigen oder ein Abonnement abschließen.<br/>
                            Die Schnellübersichten der einzelnen Titel und die Infothek stehen dennoch wie
                            gewohnt zur Verfügung.<br/>
                            Viel Spaß beim Stöbern!<br/><br/>
                            Die Königs-Erläuterungen-Redaktion
                        </div>
                    </div>
                </Fragment>)}
            </Modal.Body>
        </Modal>)
    }

    renderMobile() {
        return (<div className="landing-background" style={{position: 'relative'}}>
            {this.renderModal()}

            <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false}
                        transparent={true} transparentMobile={true}/>
            <div style={{paddingLeft: 30}} className="container-fluid teaser-container">
                <div className="row teaserContent">
                    <div className="col-md-7 col-sm-12">
                        <div className="mb-md-5">
                            <img className="mb-md-5" alt="keLogo" src={keLogo} width="100%" height="auto"
                                 style={{marginBottom: '1.5rem', maxWidth: "750px"}}/>
                            <h1 className="headingTeaser" style={{lineHeight: "1.3em"}}>Das
                                Lektürehilfen-Portal</h1>
                        </div>
                        <div style={{marginTop: 50}}>
                            <SearchBarContainer className={"teaser-searchbar"} onSubmit={this.onSearchSubmit}
                                                onChange={(value) => {
                                                    this.onChange(value)
                                                }} colorScheme="white"/>
                        </div>
                        <div style={{marginLeft: 15, marginTop: 10}}>
                            {this.renderSearchResults()}
                        </div>
                    </div>
                    <div className="col-md-5 d-none d-md-block">
                        <img alt="keIllustration" src={dudeImg} className={"bounce"}
                             style={{height: "auto", width: "100%", maxWidth: "350px"}}/>
                    </div>
                </div>
                {this.state.activeSubscription ? null : <div className="buy-button-wrapper">
                    <div
                        className="button classic mediumButton buy-button header-button"
                        onClick={() => {
                            this.setState({openModal: true})
                        }}
                    >
                        Jetzt alle Texte freischalten
                    </div>
                </div>}
                <div className="buy-button-wrapper">
                    <div
                        className="button classic mediumButton buy-button header-button"
                        onClick={this.onClickCoupon.bind(this)}
                    >
                        Jetzt E-Book-Code einl&ouml;sen
                    </div>
                </div>
            </div>
        </div>)
    }

    render() {
        if (isMobile) {
            return this.renderMobile();
        }
        return (<div className="landing-background" style={{position: 'relative'}}>
            {this.renderModal()}

            <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} searchBar={false} stickyTop={false}
                        transparent={true} transparentMobile={true}/>
            <div style={{paddingLeft: 30}} className="container-fluid teaser-container">
                <div className="row teaserContent">
                    <div className="col-md-7 col-sm-12">
                        <div className="mb-md-5">
                            <img alt="keLogo" className="mb-md-5" src={keLogo} width="100%" height="auto"
                                 style={{marginBottom: '1.5rem', maxWidth: "750px"}}/>
                            <h1 className="headingTeaser">Das Lektürehilfen-Portal</h1>
                        </div>
                        <div style={{marginTop: 50}}>
                            <SearchBarContainer className={"teaser-searchbar"} onSubmit={this.onSearchSubmit}
                                                onChange={(value) => {
                                                    this.onChange(value)
                                                }} size="dynamic" colorScheme="white"/>
                        </div>
                        <div style={{marginLeft: 15, marginTop: 10}}>
                            {this.renderSearchResults()}
                        </div>
                    </div>
                    <div className="col-md-5 d-none d-md-block" style={{textAlign: "center"}}>
                        <img alt="keIllustration" src={dudeImg} className={"bounce"}
                             style={{height: "auto", width: "100%", maxWidth: "320px"}}/>
                        <div>
                            {this.state.activeSubscription ? null : <div
                                className="button classic mediumButton buy-button"
                                style={{marginBottom: "30px", marginLeft: "auto", marginRight: "auto"}}
                                onClick={() => {
                                    this.setState({modalAction: 'abo', modalState: 'preBuyOrSub', openModal: true})
                                }}
                            >
                                Jetzt alle Texte freischalten
                            </div>}
                            <div
                                className="button classic mediumButton buy-button"
                                style={{marginBottom: "30px", marginLeft: "auto", marginRight: "auto"}}
                                onClick={this.onClickCoupon.bind(this)}
                            >
                                Jetzt E-Book-Code einl&ouml;sen
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    onClickCoupon = () => {
        console.log('onClickCoupon');
        if (this.props.authenticated) {
            // user already logged in , skip to complete purchase state, set pruchase state
            this.setState({openModal: true, modalState: "completeCoupon", modalAction: "coupon"});
        } else {
            // set modal to login state
            this.setState({openModal: true, modalState: "login", modalAction: "coupon"});
        }
    }
}

Teaser.propTypes = {
    /**
     * The small stats data.
     */

};

Teaser.defaultProps = {};

const mapStateToProps = state => ({
    currentChapter: state.content.currentChapter,
    currentBook: state.content.currentBook,
    books: state.content.books,
    searchResult: state.content.searchResult,
    searchTerm: state.content.searchTerm,
    authenticated: state.authentication.authenticated,
    flatrate: state.products.flatrate,
    singleBook: state.products.singleBook
});

const mapDispatchToProps = dispatch => ({
    fetchBookByAuthorAndTitle: (author, title, successCb, errorCb) => dispatch(fetchBookByAuthorAndTitle(author, title, successCb, errorCb)),
    fetchChapterById: (chapterId, successCb, errorCb) => dispatch(fetchChapterById(chapterId, successCb, errorCb)),
    search: (searchTerm, successCb, errorCb) => dispatch(ContentService.search(searchTerm, successCb, errorCb)),
    orderBook: (bookid, successCb, errorCb) => dispatch(orderBook(bookid, successCb, errorCb)),
    login: (email, password, successCb, errorCb) => dispatch(loginUser(email, password, successCb, errorCb)),
    getUserActiveSubscription: (searchterm, successCb, errorCb) => dispatch(getUserActiveSubscription(searchterm, successCb, errorCb)),
    getProduct: (code, successCb, errorCb) => dispatch(getProduct(code, successCb, errorCb))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teaser));

function SearchItem({item}) {
    return (<a href={item.link} style={{textDecoration: "none", outline: "none"}}>
        <div className={"searchResultAutoCompleteItem"}>
            {item && item.title}
        </div>
    </a>)
}

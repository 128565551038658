import React from 'react';
import PropTypes, { oneOf } from 'prop-types';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

import './SearchBar.css';

class SearchBar extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            searchValue: ""
        }


    }

    onClick = () => {
      const {resetable, searchTerm, onReset, onSubmit } = this.props;
      if(resetable && searchTerm.length > 0 && onReset) {
        onReset();
      }

      if(searchTerm.length >0 && onSubmit) {
        onSubmit();
      }
    }

    renderInputButton = () => {
      const {resetable, searchTerm,height, theme, } = this.props;

      if(resetable) {
        return (
          <div  onClick={() => {this.onClick()}} style={{height:  (height ? height *0.9 : 46), width:  (height ? height *0.9 : 46), marginRight: 2}} className={"roundedMagnifier" + (searchTerm.length > 0 && theme !== "viewer"  ? " transparent" : "")}>
            {searchTerm.length === 0 ? <Search  style={{color:"white", width: (height ? (height / 45)*28 :28), height:  (height ? (height / 45)*28 :28)}}/> : <Close style={{color:(theme !== "viewer" ? "black": "white"), width: (height ? (height / 45)*28 :28), height:  (height ? (height / 45)*28 :28)}}/> }
          </div>
        )
      } else {
        return (
          <div onClick={() => {this.onClick()}} style={{height:  (height ?height *0.9 : 46), width:  (height ? height *0.9 : 46), marginRight: 2}} className={"roundedMagnifier"}>
            <Search  style={{color:"white", width: (height ? (height / 45)*28 :28), height:  (height ? (height / 45)*28 :28)}}/>
          </div>
        )
      }

    }

    render(){
        const {searchTerm, height, size, colorScheme,onChange,onEnter,  inputStyles, styles, className,placeHolder} = this.props;

        const containerStyles = Object.assign({}, {height: (height ? height : 50), display: "flex", alignItems:"center",justifyContent:"space-between"}, styles);
        if (size === "dynamic") return (
            <div style={containerStyles} className={"searchBar " + colorScheme + " " + size + "SearchBar" + (className ? " "+className : "")}>
              <input onKeyUp={(e) => {if(e.keyCode === 13 && onEnter) {onEnter()}}} value={searchTerm}  onChange={(e) => onChange(e.target.value)} placeholder={placeHolder?placeHolder:"Suchen"} className={"searchInput"} style={Object.assign({background: "transparent",height:  (height ? height *0.9 : 50),maxWidth:"85%",width:"85%"},inputStyles)} />

              {this.renderInputButton()}
            </div>
        )
    }
}

SearchBar.propTypes = {
    placeHolder: PropTypes.string,
    size: oneOf(["small", "medium","dynamic"]).isRequired,
    colorScheme: oneOf(["white", "gray", "grayBorder"]).isRequired,
    onSubmit: PropTypes.func
}

export default SearchBar;

import React from 'react';
import BookPreviewCardAlternative from '../bookPreviewCardAlternative/BookPreviewCardAlternative';
import Heading from '../heading/Heading';
import aboSample from '../../assets/images/CoverAbo.png';
import CheckboxWithLabel from '../Checkbox/CheckboxWithLabel';
import './modalcontentbuyorsubscribe.css';
import { isMobile } from "../../utils/device";



function SubscribeModalContent({ book, onClickKaufen, onClickAbo, singleBookPrice, flatratePrice, taxPercentageSingle, taxPercentageFlatrate }) {
  const [checkbox,setCheckbox] = React.useState(false);
  const [checkbox2,setCheckbox2] = React.useState(false);
  const changeCheckbox = (e) => {
    setCheckbox(e.target.checked);
  }

  const actionsCheckbox = {
    changeHandler: changeCheckbox
  };

    const defaultCheckbox = {
        label: (<React.Fragment>
            Mit Klick auf „Für {flatratePrice.toLocaleString('de-DE')} € abonnieren“
            erkläre ich mich mit der Geltung der <a href={"/infothek/agb/"} target={"_blank"}>AGB</a> einverstanden.
            Von meinem <a href={"/infothek/agb/"} target={"_blank"}>Widerrufsrecht</a> und der <a
            href={"/infothek/datenschutz/"} target={"_blank"}>Datenschutzerklärung</a> habe ich Kenntnis genommen.
        </React.Fragment>),
    required: true
  }

  const changeCheckbox2 = (e) => {
    setCheckbox2(e.target.checked);
  }

  const actionsCheckbox2 = {
    changeHandler: changeCheckbox2
  };

    const defaultCheckbox2 = {
        label: (<React.Fragment>
            Ich stimme der Ausführung des Vertrages vor Ablauf der Widerrufsfrist ausdrücklich zu. Ich habe zur
            Kenntnis genommen, dass mein Widerrufsrecht mit Beginn der Ausführung des
            Vertrags erlischt.
        </React.Fragment>),
        required: true
    }

  if (isMobile) {
    return (
      <div className={'SubscribeModalContent isMobile'}>
        <div>
          <div className="headingStyle" style={{ fontSize: "1.8em", lineHeight: "1.0em", paddingTop: 20 }}>Gesamten Inhalt sehen ?</div>
        </div>
        <div id="scroll-to-top"/>
        <div className="mt-2">
        <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox} {...actionsCheckbox} />
        </div>
        <div className="mt-2 mb-5">
        <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox2} {...actionsCheckbox2} />
        </div>

        <div className="divider"/>

        <div>
          <div className=" mt-2">
            <div >
              <Heading type="secondary">Unlimitierter Zugang</Heading>
            </div>
            <div>
              <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                          onClickKaufen={()=>{if(!checkbox || !checkbox2) {document.getElementById('scroll-to-top').scrollIntoView();document.querySelectorAll("input[type=checkbox]").forEach(function(e){if (!e.checked) e.nextSibling.style.boxShadow="inset 0 0 0 1px rgba(200,30,40,0.9), inset 0 -1px 0 rgba(200,30,40,0.9)"});return;} onClickAbo()}}
                                          animate={false}
                                          aboBuy={true}
                                          aboDisplay={false}
                                          title={"Königs Erläuterungen Abonnement"}
                                          taxPercentage={taxPercentageFlatrate}
                                          price={flatratePrice}
                                          description={"Erhalte Zugang zu allen Inhalten des Königs Erläuterungen Portals."}
                                          coverURL={aboSample} />
            </div>
            {/* <div class="my-4">
           <span class="ml-2" style={{ color: "#565656", fontWeight: "bold", fontSize: "1.25em" }}> für nur 5,99 € pro Monat </span> <Button colorScheme="classic" title="Abbonieren" size="medium" onClick={onClickAbo}>Abonnieren</Button>
          </div> */}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={'SubscribeModalContent'}>
      <div>
        <Heading type="secondary">Gesamten Inhalt sehen ? </Heading>
      </div>
      <div id="scroll-to-top"/>
      <div className="mt-2">
        <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox} {...actionsCheckbox} />
        </div>
        <div className="mt-2 mb-5">
        <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox2} {...actionsCheckbox2} />
      </div>

      <div className="divider"/>

      <div>
        <div className=" mt-2">
          <div >
            <Heading type="secondary">Unlimitierter Zugang</Heading>
          </div>
          <div>
            <BookPreviewCardAlternative zustimmung={(checkbox === true && checkbox2 === true)}
                                        onClickKaufen={()=>{if(!checkbox || !checkbox2) {document.getElementById('scroll-to-top').scrollIntoView();document.querySelectorAll("input[type=checkbox]").forEach(function(e){if (!e.checked) e.nextSibling.style.boxShadow="inset 0 0 0 1px rgba(200,30,40,0.9), inset 0 -1px 0 rgba(200,30,40,0.9)"});return;}onClickAbo()}}
                                        animate={false}
                                        aboBuy={true}
                                        title={"Königs Erläuterungen Abonnement"}
                                        price={flatratePrice}
                                        taxPercentage={taxPercentageFlatrate}
                                        description={"Erhalte Zugang zu allen Inhalten des Königs Erläuterungen Portals."}
                                        coverURL={aboSample} />
          </div>

        </div>
      </div>
    </div>
  )
}

export default SubscribeModalContent;

import React, {Component} from "react";
import {  Link } from "react-router-dom";
import Button from "../../../../componentsBange/button/Button";
import Input from "../../../../componentsBange/Input/Input";

export default class LoginBox extends Component {

    constructor(props) {
      super(props)
      this.state = {
        email: "",
        password: "",
        resetError: false,
        pwInvalid: false,
        emailInvalid: false
      }
      this.loginBox = null;
    }

    componentDidMount(){
      //console.log("mout");
      document.body.addEventListener('click',  this.onBodyClicked);
      this.setState({email: (this.props.email?this.props.email:""), password: (this.props.password?this.props.password:"")});
    }

    componentDidUpdate() {
    }

    onBodyClicked = (event)=> {
      //console.log(event.target,"event");
      //let loginBoxId = document.getElementById("#loginBox");
      //console.log(this.loginBox);
      //console.log(loginBoxId);
      if (((this.loginBox) && this.loginBox.contains(event.target)) ||(event.target.firstChild && event.target.firstChild.nodeValue === "Anmelden")) {
          // console.log("‘clicked inside’");
      } else {

          this.props.onClickOutside(this.state.email,this.state.password);

          // console.log("‘clicked outside");

      }
    }

    componentWillUnmount(){
      //console.log("unmoount");
      document.body.removeEventListener('click',this.onBodyClicked);
    }

    keyPressed = (key) => {
      const {email, password} = this.state;
      this.setState({resetError:false});
      if (key === "Enter"){
        // enter pressed, check if both fields are valid 
        if (password.length > 0 && email.length > 0) {
          this.props.onLogin(email, password);
        }
      }
    }

    login = () => {
      const {email, password} = this.state;
      if (password.length > 0 && email.length > 0) {
        // validate inputs for malicious characters
        if (this.validatePasswort(password)) {
          if (this.validateEmail(email)) {
          this.setState({resetError:false,loading:true});
          this.props.onLogin(email, password);
          } else this.setState({emailInvalid: true});
        } else this.setState({pwInvalid: true});
        }
      }
    

    validatePasswort(pw) {
      var re = /[A-Za-z\d!§$%&]/gm;
      
      //console.log(pw,pw.match(re).length);
      if (pw.match(re).length !== pw.length) return false;
      return true;
    }

    validateEmail(email){
      var re = /\S+@\S+\.\S+/;
      //console.log(email,re.test(email));
     return re.test(email);
    }


    render() {
      const emailInput = {
        placeholder: 'E-Mail',
        label: 'E-Mail',
        type: 'email'
      }

      const passwortInput = {
        placeholder: 'Passwort',
        label: 'Passwort',
        type: 'password'
      }

      return (
        <div ref={ (ref)=> {this.loginBox = ref}} class="login-box pb-1" onKeyDown={(e)=>this.keyPressed(e.key)}>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <Input defaultValue={this.props.email} input={emailInput} onChangeHandler={(e)=> {this.setState({email: e.target.value,resetError:true,emailInvalid: false})}}/>
                {this.state.emailInvalid && (<div style={{color:"red"}}> Keine gültige E-Mail.</div>)}
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <Input defaultValue={this.props.password} onChangeHandler={(e)=> {this.setState({password: e.target.value,resetError:true, pwInvalid: false})}} input={passwortInput}  onKeyDown={(e)=>this.keyPressed(e.key)}/>
                {this.state.pwInvalid && (<div style={{color:"red"}}> Passwort enthält ungültige Zeichen.</div>)}
              </div>
            </div>
            {this.props.error && this.state.resetError===false && (<div class="row my-3">
              <div class="col-12">
                <span style={{color: "red", fontSize:"1.1em"}}>E-Mail oder Passwort inkorrekt. Bitte erneut versuchen.</span>
              </div>
            </div>)}
            <div class="row mt-1">
            <div class="col-12" style={{display:"flex",justifyContent:"center", marginTop:"10px",marginBottom:"10px"}}>
              <Button id="anmelde" loading={this.props.loading} onClick={this.login} colorScheme="classic" size="medium">Anmelden</Button>
              </div>
            <div class="col-12 mt-2 " style={{display:"flex",alignItems:"center",justifyContent: "flex-end"}}>
                <Link class="linkLoginBox" to={"/forgot-password"}>Passwort vergessen?</Link>
              </div>
              
              
            </div>
            <div class="row mb-3">
            
              <div class="col-12 mt-2 pt-2">
              <hr style={{borderTop: "2px solid #8c8b8b"}}></hr>
              <div class="mt-4">
              <span style={{fontSize: "1.6em"}}>Noch keinen Account ? </span>
              <Link class="linkLoginBox" to={"/register"}> Hier Registrieren</Link>
              </div>
                
              </div>
            </div>
          </div>
        </div>
      );
    }
}
